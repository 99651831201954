import React from 'react';
import Card from 'ui/views/cards/Card';
import PleaseEnableCookiesDialog from 'CookieConsent/PleaseEnableCookiesDialog';
import { getOrUndefined } from 'util/resource';
import { EventDTO } from 'ui/domain/Event/types';
import { staticFileLocations } from 'urls';
import HtmlContent from 'ui/elements/HtmlContent';
import useResource from 'util/resource/useResource';
import { communityAPIUrls } from 'apis/CompanyAPI/communities/communitiesApi';
import { CommunityPreview } from 'types/company/community';
import { Body, CompanyCardHeader, Container } from 'ui/layout/PublicPage';
import Chip from 'ui/elements/Chip';
import TicketStarIcon from 'ui/elements/icons/TicketStar';
import { Divider } from '@mui/material';
import EventDetails from 'pages/Event/EventDetails';
import PartnersAndHosts from '../PartnersAndHosts';
import { TinyOrganizationDTO } from 'types/organization';
import OrganizationAPI from 'apis/OrganizationAPI';
import { useCommunitySummary } from 'apis/CompanyAPI/communities/useCommunitySummary';
import MaxWidth from 'ui/views/containers/MaxWidth';
import CtaButton from 'pages/Event/CtaButton';
import SectionHeading from 'ui/elements/SectionHeading';
import LocationMap from 'domain/shared/Location/LocationMap';
import Avatar from 'ui/elements/avatars/Avatar';
import { userRole } from 'ui/domain/Users/userUtils';
import featureToggle from 'featureToggle';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { bluePlanetTheme } from 'ui/theme';
import { Link } from 'react-router-dom';
import { contentSpacing, sectionSpacing } from 'ui/theme/themeConstants';
import { spacingUnits, typography } from 'ui/theme/themeBluePlanet';
import { resize } from 'util/cloudinary';
import ScrollToHashElement from 'hooks/useScrollToHashElement';
import ClickableCard from 'ui/views/cards/ClickableCard';
import { useLoginWithRedirect } from 'auth/useLoginWithRedirect';
import ChatBubblesIcon from 'ui/elements/icons/ChatBubblesIcon';

const BackgroundImg = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 23.95%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export function EventBanner({ imageURL, className }: { imageURL?: string; className?: string }) {
  const backgroundImage = resize(imageURL || staticFileLocations.defaultCompanyImage, { width: 'auto' });
  return <BackgroundImg className={className} style={{ backgroundImage: `url(${backgroundImage})` }} />;
}

const AgendaTable = styled.table`
  td {
    padding-bottom: ${contentSpacing};
    vertical-align: top;
    min-width: 140px;
  }
  td:first-of-type {
    padding-right: ${sectionSpacing};
    font-weight: ${typography.fontWeightBold};
  }
  ${bluePlanetTheme.breakpoints.down('md')} {
    tr,
    td {
      display: block;
      padding-bottom: 0;
    }

    td:first-of-type {
      padding-right: 0;
    }
    td:last-of-type {
      padding-bottom: ${contentSpacing};
    }
  }
`;

export function McLinks() {
  return (
    <div className="u-content-spacing-bottom">
      <Link to="#info" className="text-link text-link-mc u-content-spacing-right">
        Info
      </Link>
      <Link to="#agenda" className="text-link text-link-mc u-content-spacing-right">
        Agenda
      </Link>
      <Link to="#location" className="text-link text-link-mc">
        Location
      </Link>
    </div>
  );
}

const Speakers = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacingUnits.half};
  margin-top: ${spacingUnits.half};

  ${bluePlanetTheme.breakpoints.down('sm')} {
    gap: 0;
    flex-direction: column;
  }
`;

function Speaker({ name, imageUrl, href }: { name: string; imageUrl: string; href?: string }) {
  if (!href) {
    return (
      <span>
        <img src={imageUrl} alt={name} style={{ width: '250px' }} />
      </span>
    );
  }
  return (
    <a target="_blank" href={href} rel="noreferrer">
      <img src={imageUrl} alt={name} style={{ width: '250px' }} />
    </a>
  );
}

export function McAgenda() {
  return (
    <div id="agenda" className="u-content-spacing-top">
      <h1 className="text-h3 text-x-large">Agenda</h1>
      <AgendaTable>
        <tbody>
          <tr>
            <td>14:00</td>
            <td>Registration</td>
          </tr>
          <tr>
            <td>14:30 - 14:40</td>
            <td>
              Opening - Set the Scene
              <Speakers>
                <Speaker
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/hzqmz2t4sy5e3azt98xk.png"
                  name="Andreas Spengel"
                  href="https://www.linkedin.com/in/andreas-spengel-0a97023/"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>14:40 - 15:00</td>
            <td>
              UnternehmerTUM Success Story: The European Benchmark
              <Speakers>
                <Speaker
                  name="Thomas Zeller"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/ndwnctzkvi6alslqcu8b.png"
                  href="https://www.linkedin.com/in/thomaszeller/"
                />
                <Speaker
                  name="Philipp Gerbert"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/ebn4n3lsonkmshicwuu7.png"
                  href="https://www.linkedin.com/in/philippgerbert/"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>15:00 - 15:20</td>
            <td>
              Re-inventing Embedded Finance – Unlocking new Growth Opportunities
              <Speakers>
                <Speaker
                  name="Carsten Höltkemeyer"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738933193/marketing/mc/a5spgiuf1gnfozmjdma0.png"
                  href="https://www.linkedin.com/in/carsten-h%C3%B6ltkemeyer-72b6b95a"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>15:20 – 15:40</td>
            <td>
              Panel: The Power of the Munich Tech Ecosystem
              <Speakers>
                <Speaker
                  name="Sarah Theinert"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/h0kzcydl5h3mlvxlxmit.png"
                  href="https://www.linkedin.com/in/sarahtheinert"
                />
                <Speaker
                  name="Felix Haas"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738945419/marketing/mc/i0xraa3q5zk6xyebzr5n.png"
                  href="https://www.linkedin.com/in/felixhaas/"
                />
                <Speaker
                  name="Bettine Schmitz"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1739193481/marketing/mc/zko5a4we0wxtfhe9jbhk.png"
                  href="https://www.linkedin.com/in/bettineschmitz"
                />
                <Speaker
                  name="Jennifer Kaiser-Steiner"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738934255/marketing/mc/z8kfoi3s4al1gbs4w40w.png"
                  href="https://www.linkedin.com/in/jenniferkaisersteiner/"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>15:40 - 16:00</td>
            <td>Networking Break</td>
          </tr>
          <tr>
            <td>16:00 - 16:10</td>
            <td>
              Scale your Business with Start Path
              <Speakers>
                <Speaker
                  name="Paul Graham"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/o2mcdleynxmgkf4cqd5f.png"
                  href="https://www.linkedin.com/in/davidpaulgraham"
                />
                <Speaker
                  name="Antoine Leboyer"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738844567/marketing/mc/hmbo6ke0sbya2hkzkj1t.png"
                  href="https://www.linkedin.com/in/antoineleboyer"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>16:10 - 17:10</td>
            <td>Innovating the Future together: Pitch Competition</td>
          </tr>
          <tr>
            <td>17:10 - 17:30</td>
            <td>
              Building Markets in a Platform Economy
              <Speakers>
                <Speaker
                  name="TBA"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738933194/marketing/mc/jtbqhvtsnho7wmdoz3qe.png"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>17:30 - 17:50</td>
            <td>
              Accelerating Growth in a Platform Economy
              <Speakers>
                <Speaker
                  name="TBA"
                  imageUrl="https://res.cloudinary.com/crowdworks/image/upload/v1738933194/marketing/mc/jtbqhvtsnho7wmdoz3qe.png"
                />
              </Speakers>
            </td>
          </tr>
          <tr>
            <td>17:50 - 18:00</td>
            <td>Award Ceremony</td>
          </tr>
          <tr>
            <td>18:00</td>
            <td>Network & Aperitivo</td>
          </tr>
        </tbody>
      </AgendaTable>
    </div>
  );
}

const LocationCard = styled.div`
  margin: 0 ${spacingUnits.half};
  max-width: 840px; // Padding is 40px so content width is 800px
  ${bluePlanetTheme.breakpoints.up('md')} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export default function PublicEvent({ event }: { event: EventDTO }) {
  const [showEnableCookieDialog, setShowEnableCookieDialog] = React.useState(false);
  const { resource } = useResource<CommunityPreview>(
    event.communityId ? communityAPIUrls.getPreview(event.communityId) : undefined,
  );
  const { loginWithRedirect } = useLoginWithRedirect();

  const { resource: organizationResource } = useResource<TinyOrganizationDTO>(
    event.organizationId ? OrganizationAPI.getBySlug(event.organizationId) : undefined,
  );
  const communityPreview = getOrUndefined(resource);
  const organization = getOrUndefined(organizationResource);
  const { resource: communitySummaryResource } = useCommunitySummary(communityPreview?.details.slug);
  const communitySummary = getOrUndefined(communitySummaryResource);

  const mastercardEventToggle = featureToggle.mastercardEventToggle(event.id);
  const isMastercardEvent = mastercardEventToggle !== undefined;
  const imageUrl = isMastercardEvent
    ? 'https://res.cloudinary.com/crowdworks/image/upload/v1738674363/marketing/mc/shxj8f4pnopcwqkshhzi.png'
    : (communitySummary?.imageURL ?? staticFileLocations.defaultCompanyImage);

  return (
    <div>
      <ScrollToHashElement />
      <EventBanner imageURL={imageUrl} />
      {/* the next two style overrides are to make the event page look good, they should probably be in a prop for the public pages in the future */}
      <Container style={{ background: 'white' }}>
        <Body style={{ top: 0 }}>
          <CompanyCardHeader>
            <div style={{ width: '100%' }}>
              {/* This needs to be a H1 for SEO reasons */}
              <h1 className="text-h3 u-half-spacing-bottom">{event.title}</h1>
              {!isMastercardEvent && (
                <span>
                  <Chip
                    color="purple"
                    label={
                      <span className="u-flex u-flex-align-center u-flex--gap-quarter">
                        <TicketStarIcon fontSize="small" />
                        Event
                      </span>
                    }
                  />
                </span>
              )}
              <div className="u-content-spacing-top">
                <EventDetails event={event} />
              </div>
            </div>
          </CompanyCardHeader>
          <Divider className="u-content-spacing-y" />
          {mastercardEventToggle?.hasAgenda && <McLinks />}

          <div id="info">
            <HtmlContent
              className={classNames('ql-rich-text', {
                ['ql-rich-text-mc']: isMastercardEvent,
              })}
              embeddingIsAllowed
            >
              {event.content}
            </HtmlContent>
          </div>
          {mastercardEventToggle?.hasAgenda && <McAgenda />}
          <div className="u-section-spacing-y">
            <CtaButton event={event} community={communitySummary?.details} />
          </div>
          <MaxWidth width="xs">
            <PartnersAndHosts event={event} organization={organization} community={communityPreview?.details} />
          </MaxWidth>
        </Body>
        <Card padding="none" color="light-grey" className="u-content-padding-y">
          <LocationCard>
            <MaxWidth width="xs">
              <SectionHeading id="location" heading="Contact us" />
              <div className="u-content-spacing-y">
                {event.contactPerson && (
                  <ClickableCard
                    className="u-flex u-flex-align-center u-fade-in"
                    onClick={() => loginWithRedirect('signup')}
                    avatar={
                      <Avatar size={40} imageUrl={event.contactPerson.imageUrl} userName={event.contactPerson.name} />
                    }
                    icon={<ChatBubblesIcon />}
                  >
                    <div className="u-flex u-flex--column u-align-left ">
                      <span className="text-weight-medium">{event.contactPerson.name}</span>
                      <span className="text-metadata">{userRole(event.contactPerson)}</span>
                    </div>
                  </ClickableCard>
                )}

                {event.richLocation && (
                  <div id="location">
                    <LocationMap latitude={event.richLocation?.latitude} longitude={event.richLocation?.longitude} />
                  </div>
                )}

                {event.richLocation && (
                  <Card>
                    <span className="text-subheading-2">LOCATION:</span>
                    <span className="text-small u-quarter-spacing-left">{event.richLocation.description}</span>
                  </Card>
                )}
              </div>
            </MaxWidth>
          </LocationCard>
        </Card>
      </Container>
      <PleaseEnableCookiesDialog open={showEnableCookieDialog} onClose={() => setShowEnableCookieDialog(false)} />
    </div>
  );
}
