import ApiBase from 'apis/ApiBase';
import { withPagination } from 'apis/utils';
import config from 'config';
import { Room, RoomWithUsers } from 'domain/rooms/types';
import { NewCaptableRow } from 'pages/Backoffice/Captable/UploadCaptable/types';
import { Stage } from 'types';
import { List, PaginatedResult, PaginationOptions } from 'types/api';
import { CommunityInviteDetails } from 'types/company/community';
import {
  CompanyListItem,
  ExecutiveSummaryDTO,
  FullFundingRound,
  InviteUserDTO,
  CompanyMessageList,
  PitchViewLogEntryDTO,
  PitchViewLogEntryPayloadDTO,
  RegisterCompanyResultDTO,
  UpdateActivityReminderSubscriptionDTO,
  ActivityReminderSubscriptionDTO,
  UpdateCompanyDTO,
  UploadVideoDTO,
  UserProfilesSummaryDTO,
  VideoListDTO,
  NewFundingRoundDTO,
  UpdateFundingRoundDTO,
  FundingRoundListDTO,
  Role,
  CaptableSettings,
  Captable,
  ICompany,
  CloseFundingRoundDTO,
  UpdatePitchViewDurationDTO,
  UpdateArchivedFundingRoundDTO,
  LabelDTO,
  UpdateLabelDTO,
  CompanyProfile,
  FundingRound,
  PitchDeckViewLogEntryDTO,
} from 'types/company/company';
import { UsersSearchFilter, UserFilter } from 'types/company/companyAPI';
import { DocumentDownloadDTO, UploadDocumentResponse } from 'types/documents';
import { UserProfile } from 'types/user';
import { CompanyId, dateRangeQueryParam } from 'urls';
import { joinQueryParams } from 'util/urlUtils';

export interface HighlightedSection {
  id: UUID;
  sectionName: string;
  sortOrder: number;
  users: UserProfile[];
}

export interface HighlightedSectionList {
  sections: HighlightedSection[];
}

const baseUrl = config.COMPANIES_API_URL;

export const companiesAPIUrls = {
  track: () => `${baseUrl}/ping`,
  list: () => `${baseUrl}/companies/`,
  get: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}`,
  getUserSummary: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/users/summary`,
  getPublicProfilesSuggestedForOnboarding: () => `${baseUrl}/companies/profiles/suggested-for-onboarding`,
  pulse: {
    list: (companySlug: string) => `${baseUrl}/companies/${companySlug}/pulse`,
  },
  completeness: {
    get: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/completeness`,
  },
  companyProfiles: {
    list: () => `${baseUrl}/companyProfiles`,
  },
  features: {
    list: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/features`,
    update: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/features`,
  },
  completions: {
    getConsent: () => `${baseUrl}/completions/consent`,
  },
  companyProfile: {
    get: (slug: string) => `${baseUrl}/companies/${slug}/profile`,
  },
  highightedUsers: {
    list: (companySlug: string) => `${baseUrl}/companies/${companySlug}/highlighted-users`,
  },
  fundingStage: {
    update: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/funding-stage`,
  },
  labels: {
    list: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/labels`,
  },
  pitch: {
    get: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/pitch`,
  },
  pitchDocuments: {
    list: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/pitch-documents`,
    download: (companyId: CompanyId, documentId: string) =>
      `${baseUrl}/companies/${companyId}/pitch-documents/${documentId}`,
    post: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/pitch-documents`,
    uploadedFinished: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/pitch-documents/upload-finished`,
    delete: (companyId: CompanyId, documentId: string) =>
      `${baseUrl}/companies/${companyId}/pitch-documents/${documentId}`,
  },
  videos: {
    list: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/videos`,
  },
  executiveSummary: {
    get: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/executive-summary`,
  },
  marketPotential: {
    get: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/market-potential`,
    update: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/market-potential`,
  },
  impactGoals: {
    list: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/impact-goals`,
  },
  users: {
    search: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/users/search`,
  },
  getMe: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/users/me`,
  industries: {
    listAll: () => `${baseUrl}/labels/industry`,
    listForUsersFilter: (companySlug: string) => `${baseUrl}/labels/industry/companies/${companySlug}`,
  },
  stages: {
    listAll: () => `${baseUrl}/labels/stage`,
  },
  businessDomains: {
    listAll: () => `${baseUrl}/businessDomains`,
  },
  fundingStageLabels: {
    listAll: () => `${baseUrl}/labels/funding-stage`,
  },
  fundingRound: {
    getActive: (companyId: string) => `${baseUrl}/companies/${companyId}/funding-round`,
  },
  communityMemberships: {
    list: (companyId: CompanyId) => `${companiesAPIUrls.get(companyId)}/communities`,
  },
  founder: {
    pulse: (companyId: number) => `${baseUrl}/companies/${companyId}/founder/pulse`,
  },
  insights: {
    profileVisits: (companyId: number, fromDate: Date, toDate: Date) =>
      `${baseUrl}/companies/${companyId}/insights/profile/visits?${dateRangeQueryParam(fromDate, toDate)}`,

    profileVisitsBreakdown: (companyId: number, fromDate: Date, toDate: Date) =>
      `${baseUrl}/companies/${companyId}/insights/profile/visits/breakdown?${dateRangeQueryParam(fromDate, toDate)}`,

    profileEngagement: (companyId: number, fromDate: Date, toDate: Date) =>
      `${baseUrl}/companies/${companyId}/insights/profile/engagement?${dateRangeQueryParam(fromDate, toDate)}`,

    profileEngagementLastFiveUsers: (companyId: number, fromDate: Date, toDate: Date) =>
      `${baseUrl}/companies/${companyId}/insights/profile/engagement/last-five-users?${dateRangeQueryParam(
        fromDate,
        toDate,
      )}`,
    pitchDeckViews: (companyId: number, fromDate: Date, toDate: Date) =>
      `${baseUrl}/companies/${companyId}/insights/pitch-deck?${dateRangeQueryParam(fromDate, toDate)}`,
    reach: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/insights/reach`,
    industryReach: (companyId: CompanyId) => `${baseUrl}/companies/${companyId}/insights/industry-reach`,
  },
  identity: {
    mfa: {
      get: () => `${baseUrl}/identity/mfa`,
      post: () => `${baseUrl}/identity/mfa`,
    },
  },
  promoted: {
    list: () => `${baseUrl}/promoted-companies`,
  },
};

const api = ApiBase();

export const companiesApi = {
  list: (accessToken?: string) =>
    api.get<PaginatedResult<CompanyListItem>>(companiesAPIUrls.list(), {
      headers: { Authorization: `Bearer ${accessToken}` },
    }),
  update: (companyId: CompanyId, dto: UpdateCompanyDTO) =>
    api.patch<CompanyProfile>(`${baseUrl}/companies/${companyId}`, dto),
  completions: {
    markAsUsed: (id: string) => api.post(`${baseUrl}/completions/${id}`, {}),
    postConsent: (companyId?: number, organizationId?: UUID) =>
      api.post(`${baseUrl}/completions/consent`, { companyId, organizationId }),
  },
  registration: {
    registerCompany: (
      accessToken: string | undefined,
      body: { company: { companyName: string; isCommunity: boolean }; originUrl?: string },
      {
        communityCode,
        communitySlug,
      }: {
        communityCode?: string;
        communitySlug?: string;
      },
    ) =>
      api.post<RegisterCompanyResultDTO>(
        `${baseUrl}/register${joinQueryParams([
          ['communityCode', communityCode],
          ['communitySlug', communitySlug],
        ])}`,
        body,
        accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined,
      ),
  },

  communityMembership: {
    leave: (companyId: number, communitySlug: string) =>
      api.deleteRequest(`${companiesAPIUrls.get(companyId)}/communities/${communitySlug}`),
  },
  labels: {
    list: (companySlug: string) => api.get<List<LabelDTO>>(`${baseUrl}/companies/${companySlug}/labels`),
    create: (companySlug: string, label: UpdateLabelDTO) =>
      api.post<LabelDTO>(`${baseUrl}/companies/${companySlug}/labels`, label),
    patch: (companySlug: string, labelId: UUID, dto: UpdateLabelDTO) =>
      api.patch<LabelDTO>(`${baseUrl}/companies/${companySlug}/labels/${labelId}`, dto),
    delete: (companySlug: string, labelId: UUID) =>
      api.deleteRequest<void>(`${baseUrl}/companies/${companySlug}/labels/${labelId}`),
  },
  userLabels: {
    list: (companySlug: string, cwUserId: UUID) =>
      api.get<List<LabelDTO>>(`${baseUrl}/companies/${companySlug}/user-labels/${cwUserId}`),
    create: (companySlug: string, cwUserId: UUID, labelId: UUID) =>
      api.post<LabelDTO>(`${baseUrl}/companies/${companySlug}/user-labels/${cwUserId}`, { labelId }),
    delete: (companySlug: string, cwUserId: UUID, labelId: UUID) =>
      api.deleteRequest(`${baseUrl}/companies/${companySlug}/user-labels/${cwUserId}`, { labelId }),
    bulkEdit: (companySlug: string, cwUserIds: UUID[], labelIds: UUID[], action: 'Add' | 'Remove') =>
      api.post<void>(`${baseUrl}/companies/${companySlug}/user-labels`, { cwUserIds, labelIds, action }),
  },
  industries: {
    search: (query: string, resultCount: number, selectedIndustry?: string) =>
      api.post(`${baseUrl}/labels/industry/search`, { query, resultCount, selectedIndustry }),
  },
  rooms: {
    list: (communitySlug: string, companyId: number) =>
      api.get<List<Room>>(`${baseUrl}/communities/${communitySlug}/companies/${companyId}/rooms`),
    create: (communityId: number, companyId: number, dto: RoomWithUsers) =>
      api.post<RoomWithUsers>(`${baseUrl}/communities/${communityId}/companies/${companyId}/rooms`, dto),
    get: (communitySlug: string, companyId: number, roomId: UUID) =>
      api.get<RoomWithUsers>(`${baseUrl}/communities/${communitySlug}/companies/${companyId}/rooms/${roomId}`),
  },
  companyProfile: {
    get: (companyId: number) => api.get<ICompany>(`${baseUrl}/companyProfiles/${companyId}`),
    listCompaniesByIds: (companyIds: number[]) =>
      api.post<{ values: ICompany[] }>(companiesAPIUrls.companyProfiles.list(), {
        companyIds,
      }),
    getFromInviteCode: (inviteCode: string) =>
      api.get<CommunityInviteDetails>(`${baseUrl}/companyProfiles/from-invite-code/${inviteCode}`),
  },
  captable: {
    update: (companyId: number, captable: { rows: NewCaptableRow[] }) =>
      api.post(`${baseUrl}/companies/${companyId}/captable/version`, captable),
    entries: (companyId: number, paginationOptions?: PaginationOptions, filter?: any) =>
      withPagination(`${baseUrl}/companies/${companyId}/captable/latest/entries`, paginationOptions, {
        q: filter && filter.name,
        sortBy: filter && filter.sortBy,
        sortDesc: filter && filter.sortDesc,
      }),

    get: (companyId: number) => api.get<Captable>(`${baseUrl}/companies/${companyId}/captable/latest`),
    getSettings: (companyId: number) =>
      api.get<CaptableSettings>(`${baseUrl}/companies/${companyId}/captable/settings`),
    updateSettings: (companyId: number, settings: CaptableSettings) =>
      api.patch<CaptableSettings>(`${baseUrl}/companies/${companyId}/captable/settings`, settings),
  },
  userInvites: {
    inviteUnregisteredUsers: (companyId: number) =>
      api.post(`${baseUrl}/companies/${companyId}/user-invites/invite-from-captable`, {}),
    invite: (companyId: number, users: InviteUserDTO[]) =>
      api.post(`${baseUrl}/companies/${companyId}/user-invites`, { users }),
    retractInvitation: (companyId: number, dto: { email: string }) =>
      api.deleteRequest(`${baseUrl}/companies/${companyId}/user-invites`, dto),
    list: (
      companyId: number,
      paginationOptions?: PaginationOptions,
      filter?: {
        email?: string;
        sortBy?: 'inviteDate' | 'email';
        sortOrder?: 'ASC' | 'DESC';
      },
    ) => withPagination(`${companiesAPIUrls.get(companyId)}/user-invites`, paginationOptions, filter),
  },
  users: {
    list: (companyId: number | string, paginationOptions?: PaginationOptions, filter?: UserFilter) =>
      withPagination(`${baseUrl}/companies/${companyId}/users`, paginationOptions, {
        q: filter && filter.name,
        role: filter && filter.role,
        email: filter && filter.email,
        label: filter && filter.label,
        sortBy: filter && filter.sortBy,
        sortOrder: filter && filter.sortOrder,
        audience: filter && filter.audience,
        lastActive: filter && filter.lastActive,
      }),
    search: (companySlug: string, params?: UsersSearchFilter) =>
      api.get<PaginatedResult<UserProfile>>(companiesAPIUrls.users.search(companySlug), {
        requestParams: params,
      }),
    contacts: {
      list: (companyId: number) =>
        api.get<PaginatedResult<UserProfile>>(`${baseUrl}/companies/${companyId}/users/contact-info`),
    },
  },
  admin: {
    updateBannerImage: (companyId: number, url: string) =>
      api.patch(`${baseUrl}/companies/${companyId}/bannerImage`, {
        imageURL: url,
      }),
    updateLogo: (companyId: number, url: string) =>
      api.patch(`${baseUrl}/companies/${companyId}/logo`, {
        logoURL: url,
      }),
    commitment: {
      get: (companyId: CompanyId) =>
        api.get<ActivityReminderSubscriptionDTO>(`${baseUrl}/companies/${companyId}/update-commitment`),
      save: (companyId: CompanyId, dto: UpdateActivityReminderSubscriptionDTO) =>
        api.post<ActivityReminderSubscriptionDTO>(`${baseUrl}/companies/${companyId}/update-commitment`, dto),
    },
    removeAccess: (companySlug: string, userId: number) =>
      api.deleteRequest(withPagination(`${companiesAPIUrls.get(companySlug)}/admin/user/${userId}`)),
    editRoles: (companySlug: string, userId: number, roles: Role[]) =>
      api.put<void>(`${companiesAPIUrls.get(companySlug)}/admin/user/${userId}/roles`, { roles }),
  },
  messages: {
    pitch: (companyId: number) => api.get<CompanyMessageList>(`${baseUrl}/companies/${companyId}/messages/pitch`),
    overview: (companyId: number) => api.get<CompanyMessageList>(`${baseUrl}/companies/${companyId}/messages/overview`),
  },
  executiveSummary: {
    patch: (companySlug: string, payload: ExecutiveSummaryDTO) =>
      api.put<ExecutiveSummaryDTO>(`${baseUrl}/companies/${companySlug}/executive-summary`, payload),
  },
  teamDescription: {
    patch: (companyId: number, payload: { teamDescription?: string }) =>
      api.put<ExecutiveSummaryDTO>(`${baseUrl}/companies/${companyId}/executive-summary`, payload),
  },
  videos: {
    get: (companyId: number) => api.get<VideoListDTO>(`${baseUrl}/companies/${companyId}/videos`),
    post: (companyId: number, videos: UploadVideoDTO[]) =>
      api.post<VideoListDTO>(`${baseUrl}/companies/${companyId}/videos`, { videos }),
  },
  profile: {
    highlightedUsers: {
      post: (companySlug: string, sectionName: string, cwUserIds: UUID[]) =>
        api.post<HighlightedSectionList>(`${baseUrl}/companies/${companySlug}/highlighted-users`, {
          sectionName,
          cwUserIds,
        }),
      patch: (companySlug: string, sectionId: UUID, sectionName: string, cwUserIds: UUID[]) =>
        api.patch<HighlightedSectionList>(`${baseUrl}/companies/${companySlug}/highlighted-users/${sectionId}`, {
          sectionName,
          cwUserIds,
        }),
      patchOrder: (companySlug: string, sectionIdsOrdered: UUID[]) =>
        api.patch<HighlightedSectionList>(`${baseUrl}/companies/${companySlug}/highlighted-users/order`, {
          sectionIds: sectionIdsOrdered,
        }),
      delete: (companySlug: string, sectionId: UUID) =>
        api.deleteRequest<HighlightedSectionList>(`${baseUrl}/companies/${companySlug}/highlighted-users/${sectionId}`),
    },

    logView: (companyId: number | string, pitchView: PitchViewLogEntryPayloadDTO) =>
      api.post<PitchViewLogEntryDTO>(`${baseUrl}/companies/${companyId}/pitch-views`, pitchView),
    logDuration: (companyId: number | string, pitchViewId: string, body: UpdatePitchViewDurationDTO) =>
      api.patch(`${baseUrl}/companies/${companyId}/pitch-views/${pitchViewId}`, body),
    pitchDocuments: {
      url: (companyId: number, documentId: string, downloadType: 'preview' | 'download') =>
        `${baseUrl}/companies/${companyId}/pitch-documents/${documentId}?downloadType=${downloadType}`,
      list: (companyId: number) => api.get<DocumentDownloadDTO>(`${baseUrl}/companies/${companyId}/pitch-documents`),
      download: (companyId: number, documentId: string, downloadType: 'preview' | 'download') =>
        api.get<DocumentDownloadDTO>(
          `${baseUrl}/companies/${companyId}/pitch-documents/${documentId}?downloadType=${downloadType}`,
        ),
      post: (companyId: number, payload: { filename: string; documentType: string }) =>
        api.post<UploadDocumentResponse>(`${baseUrl}/companies/${companyId}/pitch-documents`, payload),
      delete: (companyId: number, documentId: string) =>
        api.deleteRequest(`${baseUrl}/companies/${companyId}/pitch-documents/${documentId}`),
      views: {
        logView: (companyId: number, body: PitchDeckViewLogEntryDTO) =>
          api.post(`${baseUrl}/companies/${companyId}/pitch-deck-views`, body),
      },
    },
  },
  fundingRound: {
    getActive: (companySlug: string) => api.get<FundingRound>(`${baseUrl}/companies/${companySlug}/funding-round`),
    listArchive: (companyId: number) =>
      api.get<FundingRoundListDTO>(`${baseUrl}/companies/${companyId}/funding-round/archive`),
    create: (companyId: number, fundingRound: NewFundingRoundDTO) =>
      api.post<void>(`${baseUrl}/companies/${companyId}/funding-round`, fundingRound),
    update: (companyId: number, fundingRound: UpdateFundingRoundDTO) =>
      api.patch<FullFundingRound>(`${baseUrl}/companies/${companyId}/funding-round`, fundingRound),
    updateArchivedRound: (companyId: number, fundingRoundId: UUID, archivedRound: UpdateArchivedFundingRoundDTO) =>
      api.patch<void>(`${baseUrl}/companies/${companyId}/funding-round/archive/${fundingRoundId}`, archivedRound),
    close: (companyId: number, fundingRound: CloseFundingRoundDTO) =>
      api.post<void>(`${baseUrl}/companies/${companyId}/funding-round/close`, fundingRound),
  },
  network: {
    users: (companyId: number, nameFilter: string) =>
      api.get<List<UserProfile>>(`${baseUrl}/companies/${companyId}/user-network?name=${nameFilter}`),
  },
  fundingStage: {
    update: (companyId: number, fundingStageId: string) =>
      api.put<Stage>(companiesAPIUrls.fundingStage.update(companyId), {
        labelId: fundingStageId,
      }),
  },
  userSummary: (companyId: number) => api.get<UserProfilesSummaryDTO>(companiesAPIUrls.getUserSummary(companyId)),
};
